import { useContext, useEffect, useState } from 'react'
import { useStripe } from '@stripe/react-stripe-js'
import LangContext from '../../context/LangContext'
import UserContext from '../../context/UserContext'
import { toast } from 'react-toastify'
import EventsApi from './api'

const EventRegistration = ({ event, onGoBackClick, onRegistered }) => {
    const stripe = useStripe()
    const eventsApi = new EventsApi()
    const { userData } = useContext(UserContext)
    const { langData: { events: eventLang }} = useContext(LangContext);
    const [email, setEmail] = useState(localStorage.getItem('event-email'))
    const [isSubscriber, setIsSubscriber] = useState(false)
    const [registrationForm, setRegistrationForm] = useState({})

    // function stylizeDate(raw_date) {
    //     const date_string = new Date(raw_date)?.toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })
    //     const no_year = date_string?.replace(/, \d{4}/, '')
    //     return no_year || raw_date
    // }
    

    function stylizeDate(raw_date) {
        const offset = new Date().getTimezoneOffset() * 60000
        const testing = new Date(raw_date).getTime() + offset
        const date_string = new Date(testing)?.toDateString()
        const no_year = date_string?.replace(/ \d{4}/, '')
        return no_year || raw_date
    }

    function stylizeTime(time) {
        if (!time) return ''
        let split_time = time.split(':')
        let [h, m] = split_time.map(time => parseInt(time))
        let am_pm = 'AM'
        if (h > 12) {
            h = h - 12
            am_pm = 'PM'
        }
        if (m == 0) m = '00'
        return `${h}:${m} ${am_pm}`
    }

    function stylizeLocation(raw_event, line = 1) {
        if (!raw_event.address_1) return ''
        let { address_1, address_2, city, state, zip } = raw_event
        address_2 = !!address_2 && address_2 != 'null'
            ? address_2
            : ''

        const location_string = line == 1
            ? `${address_1} ${address_2}`
            : `${city}, ${state}, ${zip}`
        return location_string
    }

    function registrationFormChange(e) {
        setRegistrationForm({ ...registrationForm, [e.target.name]: e.target.value })
    }

    function getCheckoutDisabled(event) {
        return event.is_registered || event.status == 'closed'
    }

    function getCheckoutText(event) {
        const is_expired = false
        return event.is_registered 
            ? 'Registered' 
            : event.status == 'closed'
                ? eventLang.closed_button
                : is_expired
                    ? eventLang.expired_button
                    : eventLang.checkout_button
    }

    async function onPurchaseClick(e) {
        e?.preventDefault()
        try {
            console.log("LETS DO THIS!")
            // Store the email used to register (if applicable)
            if (email) localStorage.setItem('event-email', email)

            // Initialize and Redirect to Stripe Checkout
            const session = await eventsApi.startRegistration(event.id, email, registrationForm)
            const { sessionId, free } = session?.data || {}
            if (free) {
                await onRegistered()
                return toast.success(eventLang.registered_success_toast)
            }
            if (!sessionId) return toast.error(eventLang.failed_to_checkout_toast)
            await stripe.redirectToCheckout({ sessionId })
        } catch (err) {
            console.log("somethings not somethining")
            console.log(err.response.status)
            console.error(err)
            if (err?.response?.status == 409) return toast.warning(eventLang.already_registered_toast)
            if (err?.response?.status == 422) return toast.warning(eventLang.required_fields_toast)
            toast.error(eventLang.something_went_wrong_toast)
        }
    }

    async function asyncSetupEventRegistration() {
        console.log(userData)
        setIsSubscriber(!!userData.userId)
    }

    useEffect(() => { asyncSetupEventRegistration() }, [])

    return (
        <div>
            {/* Info Heading */}
            <h2 className="h2 mb-3">{event.title}</h2>
            {/* <p className="registration-description">{event.description}</p> */}

            {/* <div className='d-flex flex-row justify-content-between mt-3'>
                {/* Time, Date, Location */}
            {/*<div className='d-flex flex-column justify-content-between mr-5'> */}
            <div className='mb-5'>
                <b>{eventLang.time_and_date_header}</b>
                <p>{stylizeDate(event.date)}</p>
                <p>{stylizeTime(event.start_time)} to {stylizeTime(event.end_time)}</p>
            </div>
            <div className='mb-5'>
                <b>{eventLang.location_header}</b>
                <p>{stylizeLocation(event)}</p>
                <p>{stylizeLocation(event, 2)}</p>
            </div>
            {/* </div> */}

            {/* Price, Benefits */}
            {/* <div className='d-flex flex-column justify-content-between ml-5 flex-grow-1'> */}
            <b>{eventLang.benefits_header}</b>
            {event.benefits?.map((benefit) => (
                <div>
                    <p>{benefit.benefit}</p>
                </div>
            ))}
            <div className='mt-5'>
                <div className='d-flex'>
                    <h2 className="h2">${isSubscriber ? event.subscriber_cost : event.public_cost}</h2>
                    <h3 className='h3 ml-2'>/ {eventLang.seat}</h3>
                </div>
            </div>
            {/* </div> */}
            {/* </div> */}

            <div className="d-flex flex-column align-items-center gap-3 mb-2">
                <div className='w-100'>
                    <form onSubmit={onPurchaseClick}>
                        <hr className='mt-1 mb-3'/>
                        <h3 className='h3'>{eventLang.register_today}</h3>
                        {/* Registration Form */}
                        <div className='mb-3'>
                            <b>{eventLang.full_name_label}</b>
                            <input onChange={registrationFormChange} name="name" className="form-control w-100 mt-1" placeholder='Full Legal Name' />
                        </div>
                        <div className='mb-2'>
                            <b>{eventLang.phone_label}</b>
                            <input onChange={registrationFormChange} name="phone" className="form-control w-100 mt-1" placeholder='Phone Number' />
                        </div>
                        <div className='mb-2'>
                            <b>{eventLang.cal_fire_card_label}</b>
                            <input onChange={registrationFormChange} name="cal_fire_card" className="form-control w-100 mt-1" placeholder='Cal Fire Card Number' />
                        </div>

                        <div className={isSubscriber ? 'd-none' : 'mt-2'}>
                            <b>{eventLang.email_label}</b>
                            <input type='email' name="email" required onChange={(e) => setEmail(e.target.value)} placeholder={email || 'Enter your email to proceed...'} className='form-control w-100 mt-1' />
                        </div>

                        <button onClick={onPurchaseClick} className='w-100 mt-4' disabled={getCheckoutDisabled(event)}>{getCheckoutText(event)}</button>
                    </form>
                </div>
                <button onClick={onGoBackClick} className="btn btn-outline-secondary w-100">{eventLang.event_details}</button>

                {/* Disclaimer for companies doing multiple seats */}
                <small className='text-center mt-1'><i>{eventLang.refund_disclaimer}</i></small>
                <hr className='my-1'/>
                <small className='text-center'>{eventLang.help_disclaimer}<a href="tel:9512213405">951-221-3405</a></small>
            </div>
        </div>
    )
}

export default EventRegistration