import React, { useState, useEffect, useContext } from "react";
import SignUp from '../../components/Auth/SignUp';
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Popup } from "reactjs-popup";
import axios from "axios";
import "./courses.css";

import UserContext from "../../context/UserContext";
import LangContext from "../../context/LangContext";
import AbsorbPurchaseTokens from "../AbsorbPurchaseTokens";
import EnglishCeuInstructions from '../../assets/How to get started with your CEU Courses.pdf'
import SpanishCeuInstructions from '../../assets/Cómo empezar con tus cursos CEU.pdf'

const TESTING_MODE = false

const AbsorbCourses = () => {
  const navigate = useNavigate();
  const { userData } = useContext(UserContext);
  const { langData } = useContext(LangContext);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isSuper, setIsSuper] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isIndividual, setIsIndividual] = useState(false);
  const [isEmployee, setIsEmployee] = useState(false);
  const [isNonSub, setIsNonSub] = useState(false);
  const [masterList, setMasterList] = useState([]);
  const [learner, setLearner] = useState({});
  const [buttonMash, setButtonMash] = useState(false)

  const fetchCourseData = async (learnerData) => {
    try {
      if (TESTING_MODE) return setMasterList([{
        Id: '1',
        Name: 'Course Name',
        Status: 'active',
        Audience: 'en',
        Description: "Course Description that's a description of the course when the course is being described",
        LearnerCost: '2',
      }])

      const courseData = await axios.get(
        `${process.env.REACT_APP_API_URL}/absorb/courses/${learnerData.learner_id}`
      );

      console.log(courseData.data);
      setMasterList(courseData.data);
    } catch (err) {
      // console.log(err);
    }
  };

  const fetchLearnerData = async () => {
    try {
      const learnerData = await axios.get(
        `${process.env.REACT_APP_API_URL}/absorb/learner/${userData.userId}`
      );

      // console.log(learnerData.data);
      setLearner(learnerData.data);
      fetchCourseData(learnerData.data);
    } catch (err) {
      // console.log(err);
    }
  };

  const onEnroll = async (e, current_course_id, cost) => {
    setButtonMash(true)
    setTimeout(() => setButtonMash(false), 10000)
    const course_id = e.target.value;
    await axios
      .post(`${process.env.REACT_APP_API_URL}/absorb/enroll`, {
        account_id: userData.userId,
        course_id: current_course_id,
        learner_id: learner.learner_id,
        cost: cost,
        email: userData.user.login,
      })
      .then(({ data }) => {
        toast.success("Enrolled Successfully");
        fetchLearnerData();
      })
      .catch((err) => {
        // console.log(err);
        if (err.response.status == 401)
          return toast.error("Insuffienent Tokens");
        if (err.response.status == 422)
          return toast.error(
            "Your Learner ID is either missing or invalid, Please contact an Administrator"
          );
        toast.error("There was an issue enrolling");
      });
  };

  const redirectToAbsorb = (e) => {
    window.location.href = "https://acteducate.myabsorb.com/#/courses";
  };

  const getCourseColor = (status) => {
    let course_color = "";

    if (status < 0) {
      course_color = "white";
    } else if (status >= 0 && status < 3) {
      course_color = "#0d6efd";
    } else {
      course_color = "rgb(25 135 84)";
    }

    return course_color;
  };

  function formatCourseDescription(description) {
    try { 
      return description.split(/[<>]/)[2].split("&nbsp;")[0]
    } catch (err) {
      return description
    }
  }
  
  function getButtonText(status) {
    return status > -1
      ? status < 3
        ? langData.absorb_courses.button_enrolled
        : langData.absorb_courses.button_completed
      : isNonSub
        ? langData.absorb_courses.button_sign_up_to_enroll
        : langData.absorb_courses.button_enroll
  }

  function getCourseButtonDisabled(status) {
    return isNonSub
      ? true
      : status > -1
  }

  function signUpNonSub() {
    console.log("I AM THE SIGN UP HEAR ME ROAR")
  }

  useEffect(() => {
    if (userData.user !== undefined) {
      setIsLoggedIn(true);
      setIsSuper(userData.user.isSuper);
      setIsAdmin(userData.user.isAdmin);
      setIsIndividual(userData.user.isIndividual);
      setIsEmployee(
        !userData.user.isSuper &&
        !userData.user.isAdmin &&
        !userData.user.isIndividual
      );
      fetchLearnerData();
    } else {
      setIsSuper(false);
      setIsAdmin(false);
      setIsIndividual(true);
      setIsNonSub(true);
      setIsEmployee(false);
      fetchCourseData({ learner_id: "non-sub" });
    }
  }, []);

  return (
    <div className="absorb-courses mb-5">
      {/* <div className="card absorb disclaim-footer m-3 p-3 mt-5">
        <p>
          {isNonSub
            ? "To purchase tokens, you may sign up from the home page as a subscriber. Your subscription as a Cal Fire Fitter includes two tokens on sign up, one token per month, and a 50% discount on any additional tokens. If you'd prefer not to subscribe, you may still purchase tokens by clicking the 'Sign Up Without Subscription' button below to sign up as a learner-only account (without paying a subscriptions) and purchase tokens to redeem for CEU Courses."
            : "Please note that tokens hold no monetary value, are non refundable, and are non-transferrable."}
        </p>
        <p>{langData.absorb_courses.language_instructions}</p>
      </div> */}
      <div className="absorb course-header my-5">
        {/* <div className="absorb all-courses-wrapper"> */}
        <div className="vw-90 d-flex justify-content-between align-items-center h-100">
          <div className="header-and-tokens">
            <h1 className="h1">
              {langData.absorb_courses.header}
            </h1>
            <i className={`ml-5 ${isNonSub && 'd-none'}`}>
              {learner.tokens} {langData.absorb_courses.tokens_available}
            </i>
          </div>
          <div className="d-flex justify-content-end">
            {isLoggedIn && 
              <a target="_blank" href={langData.lang === 'en'
                ? EnglishCeuInstructions
                : SpanishCeuInstructions}>
                <button className="btn btn-outline-secondary">{langData.absorb_courses.need_help}</button>
              </a>
            }
            {isLoggedIn && (isIndividual || isAdmin) && (
              <Popup
                modal
                trigger={<button className="btn btn-outline-primary ml-3">{langData.absorb_courses.purchase_tokens}</button>}>
                  <AbsorbPurchaseTokens logged_in={true} />
              </Popup>
            )}
            {!isLoggedIn ? (
                <Popup
                  modal
                  trigger={<button
                    className="btn btn-outline-primary ml-3">
                    {langData.absorb_courses.sign_up_non_sub}
                  </button>}
                  >
                  <SignUp non_sub={true} />
                </Popup>
            ) : (
              <button 
                className="btn btn-outline-primary ml-3"
                onClick={redirectToAbsorb}>
                {langData.absorb_courses.button}
              </button>
            )}
          </div>
        </div>
        <p className="text-left my-5">
          {langData.absorb_courses.course_instructions}
        </p>
      </div>
        {/* </div> */}
        {/* <div className="absorb header-controls">
          {isAdmin ? (
            <div className="card d-flex mr-2 px-3 py-2 absorb tokens">
              <div className="d-flex justify-content-between">
                <div className="mt-3 d-flex justify-content-between">
                  <strong className="ml-3">Tokens:</strong>
                  <i>{learner.tokens} tokens</i>
                </div>
                <Popup
                  modal
                  trigger={
                    <button className="btn btn-success mt-1 mb-1">
                      Get More Tokens
                    </button>
                  }
                >
                  <AbsorbPurchaseTokens logged_in={true} />
                </Popup>
              </div>
              <div className="absorb admin-card">
                <p className="absorb admin-explain">
                  These are the tokens you've purchased and / or accrued from
                  previous employees who never used them. You can visit the{" "}
                  <a>Admin Controls</a> page and use your business tokens to
                  enroll your employees in extra courses.
                </p>
              </div>
            </div>
          ) : (
            <>
              <div
                className={
                  isSuper | !isLoggedIn
                    ? "d-none"
                    : "card d-flex mr-2 px-3 py-2 absorb tokens"
                }
                style={
                  isEmployee
                    ? { backgroundColor: "gray", color: "black" }
                    : null
                }
              >
                {!isEmployee ? (
                  <div className="d-flex justify-content-between">
                    <strong>
                      {isSuper ? null : isNonSub ? null : "Tokens:"}
                    </strong>
                    <i>{learner.tokens} tokens</i>
                  </div>
                ) : (
                  <div className="absorb employee-token-view">
                    <strong>Self Enrollment disabled</strong>
                    <p className="absorb subtext-italic">
                      Email your admin to enroll in a course
                    </p>
                  </div>
                )}
                {!isSuper && isIndividual ? (
                  <Popup
                    modal
                    trigger={
                      <button
                        className="btn btn-success mt-1"
                        style={{ height: isNonSub ? "90%" : null }}
                      >
                        {isNonSub ? "Purchase Tokens" : "Get More Tokens"}
                      </button>
                    }
                  >
                    <AbsorbPurchaseTokens logged_in={!isNonSub} />
                  </Popup>
                ) : (
                  <p></p>
                )}
              </div>
              <div
                className="card mr-2 px-3 absorb course-go-button-wrapper"
                style={{ padding: "10px" }}
              >
                {!isNonSub ? (
                  <button
                    className="btn btn-success absorb course-go-button"
                    onClick={redirectToAbsorb}
                  >
                    <p>Go To</p>
                    <p>My Courses</p>
                  </button>
                ) : (
                  <Popup
                    modal
                    trigger={
                      <button className="btn btn-success absorb course-go-button">
                        <p>Sign Up Without</p>
                        <p>Subscription</p>
                      </button>
                    }
                  >
                    <SignUp non_sub={true} />
                  </Popup>
                )}
              </div>
            </>
          )}
        </div> */}
      {/* </div> */}
      <div className="absorb course-display">
        {masterList.length == 0 
          ? ( 
          <strong>
            {langData.absorb_courses.loading_courses}
          </strong> 
          ) : 
          masterList.map((course, i) => {
            if (course.Audience == langData.lang) return (
              <div className="card text-left p-3 my-3">
                <div className="card-body">
                  <h2 className="h2">{course.Name}</h2>
                  <p>{formatCourseDescription(course.Description)}</p>
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <div className="d-flex align-items-start mt-2">
                        <b className="h5 mr-4">
                          {langData.absorb_courses.cost}
                        </b>
                        <span className="pb-1 mr-2">
                          {course.LearnerCost}&nbsp;
                          {langData.absorb_courses.tokens}
                        </span>
                      </div>
                      <div className="d-flex align-items-start">
                        <b className="h5 mr-5">
                          CEU's:
                        </b>
                        <span className="pb-1">{course.LearnerCost / 10}</span>
                      </div>
                    </div>
                    {isIndividual && (
                      <button
                        className="enroll-button"
                        value={course.Id}
                        disabled={buttonMash || getCourseButtonDisabled(course.Status)}
                        onClick={(e) =>
                          onEnroll(e, course.Id, course.LearnerCost)
                        }
                      >
                        {getButtonText(course.Status)}
                      </button>
                    )}
                  </div>
                </div>
              </div>
              // <>
              //   {course.Audience == langData.lang && (
              //     <div
              //       className="card absorb course-row"
              //       style={{
              //         color: course.Status < 0 ? "#505050" : "white",
              //         backgroundColor: getCourseColor(course.Status),
              //       }}
              //     >
              //       <div className="absorb course-row-wrapper h-100">
              //         <div className="d-flex">
              //           <strong className="mr-3">Course: </strong>
              //           <p>{course.Name}</p>
              //         </div>
              //         <div className="d-flex">
              //           <strong className="mr-3">CEU's: </strong>
              //           <p>{course.LearnerCost / 10}</p>
              //         </div>
              //         <div className="d-flex">
              //           <strong className="mr-3">Cost: </strong>
              //           <p>{course.LearnerCost} Tokens</p>
              //         </div>
              //         {course.Description && (
              //           <div>
              //             <p className="text-start my-auto">
              //               { formatCourseDescription(course.Description) }
              //             </p>
              //           </div>
              //         )}
              //       </div>
              //       {!isSuper && !isAdmin && (
              //         <div className="absorb course-button">
              //           {course.Status < 0 ? (
              //             <div>
              //               <div className="text-end">
              //                 <div>
              //                   {isIndividual ? (
              //                     <strong className="mt-2 text-right">
              //                       {!isNonSub && "Enroll in this Course"}
              //                     </strong>
              //                   ) : (
              //                     <strong className="mt-2 text-right">
              //                       Not Enrolled
              //                     </strong>
              //                   )}
              //                 </div>
              //                 {isIndividual && (
              //                   <button
              //                     className="btn btn-primary"
              //                     value={course.Id}
              //                     disabled={isNonSub}
              //                     onClick={(e) =>
              //                       onEnroll(e, course.Id, course.LearnerCost)
              //                     }
              //                   >
              //                     {isNonSub && "Sign Up to "}Enroll
              //                   </button>
              //                 )}
              //               </div>
              //             </div>
              //           ) : course.Status < 3 ? (
              //             <div className="text-end">
              //               <strong className="mt-2 text-right">
              //                 Enrolled
              //               </strong>
              //             </div>
              //           ) : (
              //             <div className="mt-5">
              //               <div className="text-end">
              //                 <div>
              //                   <strong className="mt-2 text-right">
              //                     You've Completed this Course
              //                   </strong>
              //                 </div>
              //                 {isIndividual && (
              //                   <button
              //                     className="btn btn-primary mt-1"
              //                     disabled
              //                   >
              //                     Course Completed
              //                   </button>
              //                 )}
              //               </div>
              //             </div>
              //           )}
              //         </div>
              //       )}
              //     </div>
              //   )}
              // </>
            );
          })}
      </div>
    </div>
  );
};

export default AbsorbCourses;
