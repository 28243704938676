import React, { useState, useContext, useEffect } from 'react';
import { Link, useNavigate, useLocation } from "react-router-dom"
import { Popup } from 'reactjs-popup'
import SignUp from '../../components/Auth/SignUp';
import LogIn from '../../components/Auth/LogIn';
import UserContext from '../../context/UserContext';
import LangContext from '../../context/LangContext';
import { en, sp } from '../../context/Translations';
import settingsCog from '../../images/settings_cog.png'
import './navigation.css';

import actLogo from '../../images/act-logo.png'
import hamburger from '../../images/hamburger.png'
import actWordCloud from '../../images/act-wordcloud-300x300.webp'
import { toast } from 'react-toastify';

const Header = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { userData, setUserData } = useContext(UserContext);
    const { langData, setLangData } = useContext(LangContext);

    const [isValid, setIsValid] = useState(false);
    const [isSuper, setIsSuper] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [isApprentice, setIsApprentice] = useState(false);
    const [isFitter, setIsFitter] = useState(false);
    const [isNonSub, setIsNonSub] = useState(false);
    const [expandedHamburger, setExpandHamburger] = useState(false);
    const lang_dict = { English: en, Spanish: sp };

    const logout = () => {
        setUserData({
            token: undefined,
            user: undefined,
        });


        setLangData(en);

        localStorage.setItem("auth-token", "");
        navigate("/");
        setIsValid(false);
        setIsSuper(false)
        setIsAdmin(false);
        setIsApprentice(false);
        setIsFitter(false);
        setIsNonSub(false);
    }

    const langChange = (lang) => {
        setLangData(lang === 'sp' ? sp : en);
    }

    function isCurrentRoute(route_name) {
        const path_string = location.pathname.substring(1)
        return path_string
            ? path_string.includes(route_name) ? 'selected' : ''
            : route_name === 'home' ? 'selected' : ''
    }

    function isCurrentLang(lang) {
        return lang === langData.lang && 'selected'
    }

    useEffect(() => {
        if (userData.userId) {
            console.log("\nHeader User Data: ", userData)

            setIsValid(userData.userId)
            setIsSuper(userData.user.isSuper);
            setIsAdmin(userData.user.isAdmin);
            setIsApprentice(userData.user.isApprentice);
            setIsNonSub(userData.user.isNonSub);
            if (userData.user.classification) setIsFitter(userData.user.classification.includes('fitter'));

        }
    }, [userData])

    const LogoLink = () => {
        return (
            <Link to="/">
                <img className="header-logo" src={actLogo} />
            </Link>
        )
    }

    function nonSubDeniedLink(e) {
        if (isNonSub) toast.error(langData.header.access_denied)
    }

    const NavLinkCluster = () => {
        return (
            <div className="link-bar wide">
                {/* Home */}
                <Link className={isCurrentRoute('home')} to="/">{langData.header.home}</Link>

                {/* Learn More */}
                {!isValid && <Link className={isCurrentRoute('learn-more')} to="/learn-more">{langData.header.learn_more}</Link>}

                {/* Events */}
                {true && <Link className={isCurrentRoute('events')} to="/events">{langData.header.events}</Link>}

                {/* Apprentice */}
                {(isSuper | isAdmin | isApprentice) ? <Link className={isCurrentRoute('apprentice')} to="/apprentice">{langData.header.apprentice}</Link> : null}

                {/* Courses */}
                {(!isValid | isSuper | isNonSub | isFitter) && !isApprentice ? <Link className={isCurrentRoute('courses')} to="/courses">{langData.header.courses}</Link> : null}

                {/* Licenses */}
                {isValid && !isNonSub && !isApprentice && <Link className={isCurrentRoute('licenses')} to="/licenses">{langData.header.license_and_cert}</Link>}

                {/* Jobs */}
                {isValid && <Link className={isCurrentRoute('jobs')} to={!isNonSub && "/jobs"} onClick={nonSubDeniedLink}>{langData.header.jobs}</Link>}

                {/* News Feed */}
                {isValid && <Link className={isCurrentRoute('feed')} to={!isNonSub && "/feed"} onClick={nonSubDeniedLink}>{langData.header.news}</Link>}

                {/* Super Controls */}
                {isSuper && !isNonSub && <Link className={isCurrentRoute('super')} to="/super">{langData.header.super_controls}</Link>}

                {/* Admin Controls */}
                {isAdmin && <Link className={isCurrentRoute('admin')} to="/admin">{langData.header.admin_controls}</Link>}

                {/* My Account */}
                {isValid && <Link className={isCurrentRoute('profile')} to={`/profile/${userData.userId}`}>{langData.header.profile}</Link>}

                {/* Phone */}
                <a className="call" href="tel:951-221-3405">(951) 221-3405</a>

                <Popup modal trigger={<a className="call mailing-list-link">Subscribe to Learn More</a>}>
                    <div className='form-background p-0 d-flex justify-content-center align-items-center' style={{ height: '660px', width: '610px', overflow: 'hidden' }}>
                        <iframe style={{ height: '100%', width: '100%' }} src={`${process.env.REACT_APP_API_URL}/mailchimp/form`}></iframe>
                    </div>
                </Popup>

                {/* === UNUSED LINKS / NO LONGER RELEVANT === */}
                {/* {isValid && !isNonSub && <Link className={isCurrentRoute('profile')} to={`/profile/${userData.userId}`}>
                    <div className='d-flex align-items-center'>
                        {langData.header.profile}
                        <img className='settings-cog' src={settingsCog}/>
                    </div>
                </Link>} */}
                {/* {isValid && <Link className={isCurrentRoute('settings')} to="/settings">{langData.header.settings}</Link>} */}
                {/* {isValid && !isNonSub && !isSuper && <Link className={isCurrentRoute('notifications')} to={`/notifications/${userData.userId}`}>{langData.header.notifications}</Link>} */}
            </div>
        )
    }

    const EnglishSpanishSelect = () => {
        return (
            <div className='link-bar'>
                <a onClick={() => langChange('en')} className={isCurrentLang('en')}>English</a>
                <a onClick={() => langChange('sp')} className={isCurrentLang('sp')}>Español</a>
            </div>
        )
    }

    const AuthCluster = () => {
        return (
            <div className='link-bar'>
                {isValid
                    ? (
                        <button onClick={logout}>
                            {langData.header.log_out}
                        </button>
                    ) : (
                        <>
                            <Popup
                                modal
                                trigger={<a className='sign-up'>{langData.header.sign_up}</a>}
                            >
                                <SignUp />
                            </Popup>
                            <Popup
                                modal
                                trigger={<button className='header-sign-up-button'>{langData.header.log_in}</button>}
                            >
                                <LogIn />
                            </Popup>
                        </>
                    )}
            </div>
        )
    }

    // HEADER RENDERERRRRRR
    return (
        <header>
            <div className='header-desktop d-flex align-items-center justify-content-between'>
                <div className='d-flex align-items-center'>
                    <LogoLink />
                    <NavLinkCluster />
                </div>
                <EnglishSpanishSelect />
                <AuthCluster />
            </div>
            <>
                <div className='header-mobile d-flex align-items-center justify-content-between'>
                    <LogoLink />
                    <img
                        onClick={() => setExpandHamburger(!expandedHamburger)}
                        className='hamburger-icon mr-1' src={hamburger} />
                </div>
                <div className={`hamburger-header${expandedHamburger ? ' expanded' : ''}`}>
                    <NavLinkCluster />
                    <div className='d-flex p-5 justify-content-between align-items-center'>
                        <EnglishSpanishSelect />
                        <AuthCluster />
                    </div>
                </div>
            </>
        </header>
    )

}


export default Header