import { EventCreate, EventEdit, EventHelp, EventUserPopup, EventAttendees } from './index'
import { ReactComponent as CircleQuestion } from './circle-question.svg'
import { ReactComponent as FunnelIcon } from './funnel-icon.svg'
import { useEffect, useState, useContext } from 'react'
import UserContext from '../../context/UserContext';
import LangContext from '../../context/LangContext';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify'
import Popup from 'reactjs-popup'
import EventsApi from './api'
import { writeExcel } from '../../services/excel'; // I swear to fucking shithold fuckery what the fuck is with this error
import './events.css'

const EventsPage = () => {
    const eventsApi = new EventsApi()
    const { userData } = useContext(UserContext);
    const { langData: { events: eventLang }} = useContext(LangContext);
    
    const [isSuper, setIsSuper] = useState(false)
    const [geoFilter, setGeoFilter] = useState(null)
    const [events, setEvents] = useState([])

    function calculateDuration({ start_time, end_time }) {
        if (!start_time || !end_time) return null
        const [start_h, start_m] = start_time.split(':')
        const [end_h, end_m] = end_time.split(':')
        const duration = `${Math.abs(end_h - start_h)}.${Math.abs(end_m - start_m)}`
        return duration
    }

    async function deleteEvent(event_id) {
        const confirmed = window.confirm("Are you sure you'd like to cancel this event?")
        if (!confirmed) return
        try {
            await eventsApi.deleteEvent(event_id)
            await asyncSetupEventsPage()
            toast.success('Event Deleted Successfully')
        } catch (err) {
            console.error(err)
            toast.error("Something went wrong, please try again later...")
        }
    }

    async function toggleEventClosed(event_id) {
        const confirmed = window.confirm("Are you sure you'd like to close registration for this event?")
        if (!confirmed) return
        try {
            await eventsApi.toggleEventClosed(event_id)
            await asyncSetupEventsPage()
            toast.success('Event Registration Closed Successfully')
        } catch (err) {
            console.error(err)
            toast.error("Something went wrong, please try again later...")
        }
    }

    function stylizeTime(time) {
        if (!time) return null
        let split_time = time.split(':')
        let [h, m] = split_time.map(time => parseInt(time))
        let am_pm = 'AM'
        if (h > 12) {
            h = h - 12
            am_pm = 'PM'
        }
        if (m == 0) m = '00'
        return `${h}:${m} ${am_pm}`
    }

    function stylizeDate(raw_date) {
        const offset = new Date().getTimezoneOffset() * 60000
        const testing = new Date(raw_date).getTime() + offset
        const date_string = new Date(testing)?.toDateString()
        const no_year = date_string?.replace(/ \d{4}/, '')
        return no_year || raw_date
    }

    function getRegisterButtonText(event) {

        return event.is_registered
            ? 'Event Info'
            : (event.status == 'closed' || event.is_expired)
                ? 'Registration Closed'
                : eventLang.reserve_a_seat
    }

    async function exportAttendees(attendees) {
        console.log(attendees)
        const excel_attendees = attendees.map(({ account_id, name, email, phone, cal_fire_card, status }) => ({
            'Name': name,
            'Email': email,
            'Phone': phone,
            'Cal Fire Card': cal_fire_card,
            'Status': status,
            'ACT Subscriber': !!account_id ? 'Yes' : 'No'
        }))
        await writeExcel(excel_attendees)
    }

    function eventCardClass({ date, is_registered }) {
        const event_date = new Date(date)
        const event_expired = event_date < 0 ? '' : 'event-expired'
        const event_registered = is_registered ? 'event-registered' : ''
        return `${event_expired} ${event_registered}`
    }

    function filterEvents(event_data) {
        return event_data.filter(({ title, date, status, is_expired }) => {
            
            const expired = is_expired && !isSuper
            const closed = status == 'closed' 
            console.log(`${title}: e-${expired}, c-${closed}`)
            return !expired && !closed // inverted to exclude
        })
    }

    function filterEventsByGeoArea(page_events, filter) {
        if (!filter) return events
        let filtered_events = events.filter(({ geo_area }) => geo_area == filter)
        console.log(filtered_events)
        return filtered_events
    }

    function stylizeGeoArea(goo_area) { // goo, sticky! ;)
        const capitalized = goo_area?.split('_').map(word => word[0]?.toUpperCase() + word.slice(1)).join(' ')
        return capitalized || goo_area
    }

    function onGeoFilterChange(e) {
        const { value } = e.target
        console.log("SELECTED FILTER: ", value)
        setGeoFilter(value)
        filterEventsByGeoArea(events, value)
    }

    async function asyncSetupEventsPage() { 
        // Check is super user
        const is_super = !!userData?.user?.isSuper
        console.log("is_super?", is_super)
        setIsSuper(is_super)

        // Load Events
        const api_events = await eventsApi.getEvents()
        if (!api_events?.data) return toast.error(eventLang.failed_to_load_toast)

            
        // Filter and Set Events
        let event_data = api_events.data
        event_data = event_data?.map(event => ({ ...event, description: event.description.split('\n').map(el => el == '' ? '<br/>' : `<div class="mt-2">${el}</div>`).join('') }))
        if (!is_super) event_data = filterEvents(event_data)
        setEvents(event_data)
    }

    useEffect(() => { asyncSetupEventsPage() }, [userData])

    return (
        <div className="events-page my-5">
            <div className='d-flex justify-content-between align-items-center'>
                <h1 className="h1 mb-5">
                    {eventLang.page_header}
                </h1>
                <div className="d-block">
                    <b>{eventLang.filter_location}</b>
                    <div className='d-flex align-items-center mt-1'>
                        <select onChange={onGeoFilterChange} name="geo_area" className="form-control">  {/* IT'S MY BIRTHDAY, AND i WISH TO PARTY WITH HUGH JACKMAN */}
                            <option value=''>{eventLang.filter_location_placeholder}</option>
                            <option value="virtual">Virtual</option>
                            <option value="bay_area">Bay Area</option>
                            <option value="central_california">Central California</option>
                            <option value="northern_california">Northern California</option>
                            <option value="southern_california">Southern California</option>
                        </select>
                        <div className='event-funnel mr-3'>
                            <FunnelIcon className="" />
                        </div>
                        {isSuper ? (
                            <Popup
                                modal
                                trigger={<button className='btn btn-outline-primary text-nowrap'>{eventLang.add_event_button}</button>}>
                                <EventCreate onSuccess={asyncSetupEventsPage} />
                            </Popup>
                        ) : (
                            <Popup
                                modal
                                trigger={<button className='btn btn-outline-primary'>
                                    <CircleQuestion className="circle-question mb-1" />
                                </button>}>
                                <EventHelp />
                            </Popup>
                        )}
                    </div>
                </div>
            </div>
            <div className='event-columns pt-2'>
                {filterEventsByGeoArea(events, geoFilter).map((event, i) => (
                    <div className={`card event-card ${eventCardClass(event)}`} key={`event-card-${i}`}>
                        <div className="card-body d-flex flex-column justify-content-between">
                            <h3 className="h3 card-title">{event.title}</h3>
                            <div className="event-description">
                                <p className="mb-2 mt-4" dangerouslySetInnerHTML={{ __html: event.description}}></p>
                            </div>
                            <div className="mt-4 d-flex flex-row justify-content-between align-items-center">
                                <div className='d-flex flex-column'>
                                    <p>
                                        {eventLang.credit_label}
                                        <b className='ml-2'>{event.credits || calculateDuration(event)} {eventLang.credit_sub_label}</b>
                                    </p>
                                    <p>
                                        {eventLang.date_label}
                                        <b className='ml-2'>{stylizeDate(event.date)}</b>
                                    </p>
                                    <p>
                                        {eventLang.time_label}
                                        <b className='ml-2'>{stylizeTime(event.start_time)} <span className='fw-normal'>to</span> {stylizeTime(event.end_time)}</b>
                                    </p>
                                    <p>
                                        {eventLang.location_label}
                                        <b className='ml-2'>{stylizeGeoArea(event.geo_area)} - {event.city}, {event.state} {event.zip}</b>
                                    </p>
                                </div>
                                <div className="d-flex flex-row justify-content-end align-items-center">
                                    {!isSuper && (
                                        <>
                                            <Popup
                                                modal
                                                trigger={<button disabled={event.is_expired} className={`btn ${event.is_registered ? 'd-none' : 'btn-primary'} mr-2 px-5`}>{getRegisterButtonText(event)}</button>}>
                                                <EventUserPopup event={event} open_display='register' onRegistered={asyncSetupEventsPage}/>
                                                {/* <EventRegistration event={event} /> */}
                                            </Popup>
                                            <Popup
                                                modal
                                                trigger={<button className="btn btn-primary">{eventLang.info_button}</button>}>
                                                {/* <EventInfo event={event} /> */}
                                                <EventUserPopup event={event} />
                                            </Popup>
                                        </>
                                    )}
                                    {isSuper && (
                                        <>
                                            <Popup
                                                modal
                                                trigger={<button className="btn btn-secondary px-5 ml-3">Attendees</button>}>
                                                {/* <i>Siracha, I put that on Everythang</i> */}
                                                <EventAttendees onSuccess={asyncSetupEventsPage} event={event} />
                                            </Popup>
                                            <Popup
                                                modal
                                                trigger={<button className="btn btn-secondary px-5 ml-3">Update</button>}>
                                                {/* <i>Siracha, I put that on Everythang</i> */}
                                                <EventEdit onSuccess={asyncSetupEventsPage} event={event} />
                                            </Popup>
                                            <button onClick={() => exportAttendees(event.registrations)} className="btn btn-secondary ml-3">Export</button>
                                            <button onClick={() => toggleEventClosed(event.id)} className="btn btn-warning ml-3">{ event.status == 'closed' ? 'Re-Open' : 'Close' }</button>
                                            <button onClick={() => deleteEvent(event.id)} className="btn btn-danger ml-3">Cancel</button>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default EventsPage