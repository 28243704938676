import { toast } from 'react-toastify'
import { useState } from 'react'
import EventsApi from './api'
import EventForm from './Form'
import './events.css'

const EventCreate = ({ onSuccess }) => {
    const eventsApi = new EventsApi()
    const formState = useState({ geo_area: 'bay_area', start_time: '09:00:00', end_time: '17:00:00' })
    const benefitsState = useState(['Earn credits for certification and licensing.', 'Insights from leading industry experts.', 'Connect with industry professionals and peers.'])
    const [form] = formState
    const [benefits] = benefitsState

    function validateEvent() {
        console.log("form: ", form.start_time)
        const form_keys = Object.keys(form)
        console.log(form_keys)
        const required_fields = [ "title", "description", "address_1", "city", "state", "zip", "start_time", "end_time", "subscriber_cost", "public_cost", "geo_area"]
        const valid = required_fields.every((key) => form_keys.includes(key))
        return valid
    }

    async function submit() {
        try {
            const valid = validateEvent()
            if (!valid) return toast.warning('Please enter all required info and try again...')
            await eventsApi.createEvent(form, benefits)
            await onSuccess()
            toast.success('Event Added Successfully')
        } catch (err) {
            console.error(err)
            toast.error("Something went wrong, please try again later...")
        }
    }

    return (
        <div className="form-background popup-scrollable wide-popup event-popup">
            <p className="text-sm mb-1">Event Create</p>
            <h2 className="h2 mb-3">Add a New Event</h2>

            <EventForm formState={formState} benefitsState={benefitsState} />

            {/* Button Group */}
            <div className="d-flex flex-column align-items-center gap-3 mt-5 mb-2">
                <button onClick={submit} className="btn btn-primary w-75">Save New Event</button>
                <button className="btn btn-outline-secondary w-50">Discard Changes</button>
            </div>
        </div>
    )
}

export default EventCreate