import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import axios from 'axios';

axios.interceptors.request.use(req => {
  if (req.headers['Authorization']) return req
  req.headers['Authorization'] = `Bearer ${localStorage.getItem('auth-token')}`;
  return req;
}, err => {
  return Promise.reject(err);
});

axios.interceptors.response.use((response) => response, (error) => {
  console.error("AXIOS ERROR: ", error)
  if (error?.response?.status == 409) throw error
  if (error?.response?.status == 422) throw error
})
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(// console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
