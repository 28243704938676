import React, { useEffect, useState, useContext } from 'react'
import axios from 'axios';
import UserContext from "../context/UserContext";
import LangContext from '../context/LangContext';
import { useNavigate } from 'react-router-dom';
import "./super.css"
import { toast } from 'react-toastify';
import Popup from "reactjs-popup";
import Reasons from './Reasons';
import AccountHistory from './AccountHistory';
import CEUHours from './CEUHours'
import OJLHours from './OJLHours'
import ApprenticeRequests from './ApprenticeRequests';
import SignUpApprentice from '../admincomponents/SignUpApprentice';
import { 
	PaymentRedirect, 
	SetOnboardPrice,  
	SuperAddEmployee, 
} from '../exportcomponents/PopupExports';
import AdminList from './AdminList';
import CertList from './CertList';
import PendingIndList from './PendingIndList';
import PendingAdminList from './PendingAdminList';
import PendingAdminChanges from './PendingAdminChanges';
import PendingCerts from './PendingCerts';
import JobsPending from './JobsPending';
import EmployeeChanges from '../admincomponents/EmployeeChanges'
import ViewCalendlySchedule from '../components/Calendly/ViewCalendlySchedule'
import SendCeuEmailUpdates from './SendCeuEmailUpdates.js'

const SuperFunctionality = () => {
	const { userData } = useContext(UserContext);
	const { langData } = useContext(LangContext);
	const navigate = useNavigate()
	const [onboard, setOnboard] = useState(false);
	const [pendingIndividuals, setPendingIndividuals] = useState([]);
	const [superEmail, setSuperEmail] = useState("");
	const [classification, setClassification] = useState([])
	const [certPendingList, setCertPendingList] = useState([{
		email: "",
		cert: ""
	}])
	const [certList, setCertList] = useState([{
		name: "",
		reference_number: "",
		renewal_frequency: "",
		credits_required: ""
	}]);
	const [pendList, setPendList] = useState([]);
	const [changesList, setChangesList] = useState([]);
	const [lastClicked, setLastClicked] = useState(null);
	const [redirect, setRedirect] = useState(false);
	const [redirectSession, setRedirectSession] = useState(null);
	const [redirectURL, setRedirectURL] = useState(null);
	const [redirectMessage, setRedirectMessage] = useState("");
	const [redirectQuoteID, setRedirectQuoteID] = useState("");
	const [redirectLater, setRedirectLater] = useState(false);
	const [activeDisplay, setActiveDisplay] = useState('active_subs');

	const onPaymentRedirect = (session_id, redirect_msg) => {
		if (!redirectLater) {
			setRedirectMessage(redirect_msg);
			setRedirectSession(session_id);
			// setRedirectURL()
			setRedirect(true);
		}
	}
	
	const loadPendingClassChanges = async () => {
		let accountIds = []
		const classification_pending = await axios.get(`${process.env.REACT_APP_API_URL}/account/all/classifications`);

		for(let i = 0; i < classification_pending.data.length; i++) {
			accountIds.push(classification_pending.data[i].account_id)
		}

		// console.log(accountIds)
		setClassification(accountIds);
	}

	const loadPendingIndividuals = async () => {
		const pendingIndvData = await axios.get(`${process.env.REACT_APP_API_URL}/account/pending/individual`);

		setPendingIndividuals(pendingIndvData.data);
	}

	function onLastClicked(display_name) {
		console.log("CLICKED IS LE CLICKED BOIIIIIIIIIIII: ", display_name)
		setLastClicked(display_name)
	}

	function onDisplayTabChange(display_name) {
		setActiveDisplay(display_name)
	}

	function isActiveDisplay(display_name, is_display) {
		return is_display
			? activeDisplay != display_name && 'd-none'
			: activeDisplay == display_name && 'active'
	}

	useEffect(() => {
		if (!userData.user) return navigate("/");
		setSuperEmail(userData.user.login);
		(async () => {
			try {
				const cert_data = await axios.get(`${process.env.REACT_APP_API_URL}/certification`);
				setCertList(cert_data.data);

				const pend_data = await axios.get(`${process.env.REACT_APP_API_URL}/account/pending/admin`);
				setPendList(pend_data.data);

				const individual_cert_pend = await axios.get(`${process.env.REACT_APP_API_URL}/certification/pending/individual/holder`)
				setCertPendingList(individual_cert_pend.data)

				const profile_changes_data = await axios.get(`${process.env.REACT_APP_API_URL}/account/pending/adminchanges`)
				setChangesList(profile_changes_data.data)

				loadPendingIndividuals();
				loadPendingClassChanges();

			} catch (err) {
				toast.error(err.message)
			}
		})()
	}, []);

	return (
		<div className='mt-5 super-functionality'>
			<div className='d-flex align-items-center justify-content-between'>
				<h1 className='h1'>
					Super Controls
				</h1>
				<div className='d-flex align-items-center'>
					{/* <Popup
						modal
						trigger={<button className="btn btn-outline-primary mt-2">{langData.super_functionality.view_calendly}</button>}
					>
						<ViewCalendlySchedule />
					</Popup> */}
					<Popup
						modal
						trigger={<button className="btn btn-outline-primary mt-2">{langData.super_functionality.send_ceu_update_emails}</button>}
					>
						<SendCeuEmailUpdates />
					</Popup>
					<Popup
						modal
						trigger={<button className="btn btn-outline-primary mt-2 ml-3">{langData.super_functionality.add_act_app}</button>}
					>
						<SignUpApprentice />
					</Popup>
					<Popup
						modal
						trigger={<button className="btn btn-outline-primary mt-2 ml-3">{langData.super_functionality.add_emp_to_admin}</button>}
					>
						<SuperAddEmployee />
					</Popup>
				</div>
			</div>
			<div className="mt-5 small-font">
				<ul className='nav nav-tabs nav-fill'>
					<li className='nav-item' onClick={() => onDisplayTabChange('active_subs')}>
						<a className={'nav-link ' + isActiveDisplay('active_subs')}>
							Active Subscriptions
						</a>
					</li>
					<li className='nav-item' onClick={() => onDisplayTabChange('account_history')}>
						<a className={'nav-link ' + isActiveDisplay('account_history')}>
							Account History
						</a>
					</li>
					<li className='nav-item' onClick={() => onDisplayTabChange('non_subs')}>
						<a className={'nav-link ' + isActiveDisplay('non_subs')}>
							Non Subscribers
						</a>
					</li>
					<li className='nav-item' onClick={() => onDisplayTabChange('pending_indiv')}>
						<a className={'nav-link ' + isActiveDisplay('pending_indiv')}>
							Pending Individuals
						</a>
					</li>
					<li className='nav-item' onClick={() => onDisplayTabChange('admin_list')}>
						<a className={'nav-link ' + isActiveDisplay('admin_list')}>
							CTP's
						</a>
					</li>
					<li className='nav-item' onClick={() => onDisplayTabChange('pending_admins')}>
						<a className={'nav-link ' + isActiveDisplay('pending_admins')}>
							Pending CTP's
						</a>
					</li>
					{/* <li className='nav-item' onClick={() => onDisplayTabChange('admin_change')}>
						<a className={'nav-link ' + isActiveDisplay('admin_change')}>
							Pending CTP Changes
						</a>
					</li> */}
					<li className='nav-item' onClick={() => onDisplayTabChange('pending_jobs')}>
						<a className={'nav-link ' + isActiveDisplay('pending_jobs')}>
							Jobs Pending
						</a>
					</li>
					{/* <li className='nav-item' onClick={() => onDisplayTabChange('cert_list')}>
						<a className={'nav-link ' + isActiveDisplay('cert_list')}>
							Certifications
						</a>
					</li>
					<li className='nav-item' onClick={() => onDisplayTabChange('pending_certs')}>
						<a className={'nav-link ' + isActiveDisplay('pending_certs')}>
							Certifications Pending
						</a>
					</li> */}
				</ul>
				{/* <div>
					<Popup
						modal
						onClose={() => setRedirect(false)}
						open={redirect}
						closeOnDocumentClick={false}
					>
						<PaymentRedirect
							message={redirectMessage}
							session_id={redirectSession}
							redirect_url={redirectURL}
						/>
					</Popup>
					<Popup
						modal
						onClose={() => setOnboard(false)}
						open={onboard}
						closeOnDocumentClick={false}>
							{close => (
								<div>
									<a className="close close-size" onClick={close}>
										&times;
									</a>
									<SetOnboardPrice
										session_id={redirectSession}
										quote_id={redirectQuoteID}
										redirect_message={redirectMessage}
										redirect_later={redirectLater}
										redirectCallback={onPaymentRedirect}
										super_email={superEmail} 
									/>
								</div>	
							)}
					</Popup>
				</div> */}
				<div>
					<div>
						<AccountHistory 
							classification={classification}
							onLastClicked={onLastClicked}
							activeDisplay={activeDisplay}
						/>
					</div>
					<div className={isActiveDisplay("pending_indiv", true)}>
						<PendingIndList />
					</div>
					{/* <div>
						<CEUHours />
					</div> */}
					{/* <div>
						<ApprenticeRequests />
					</div> */}
					{/* <div>
						<OJLHours />
					</div> */}
					<div className={isActiveDisplay("admin_list", true)}>
						<AdminList />
					</div>
					<div className={isActiveDisplay("pending_admins", true)}>
						<PendingAdminList />
					</div>
					{/* <div className={isActiveDisplay("admin_change", true)}>
						<PendingAdminChanges  />
					</div> */}
					{/* <div>
						<EmployeeChanges />
					</div> */}
					<div className={isActiveDisplay("pending_jobs", true)}>
						<JobsPending  />
					</div>
					{/* <div className={isActiveDisplay("cert_list", true)}>
						<CertList  />
					</div>
					<div className={isActiveDisplay("pending_certs", true)}>
						<PendingCerts  />
					</div> */}
					{/* <div>
						<Reasons />
					</div> */}
					{/* <div>
						<PendingQuotes />
					</div> */}
				</div>
			</div>
            {/* <div className='d-flex justify-content-center position-sticky bottom-0'>
                <div className='opaque p-3 w-75 d-flex justify-content-evenly align-items-center'>
                    {lastClicked}
                </div>
            </div> */}
		</div>

	)
}

export default SuperFunctionality
