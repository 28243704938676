import { useContext } from "react";
import LangContext from "../../context/LangContext";

const EventInfo = ({ event, onReserveClick }) => {
    const { langData: { events: eventLang }} = useContext(LangContext);

    return (
        <div>
            <h2 className="h2 mb-3">{event.title}</h2>
            <p dangerouslySetInnerHTML={{ __html: event.description}}></p>
            <div className="d-flex flex-column align-items-center gap-3 mt-5 mb-2">
                <button onClick={onReserveClick} disabled={event.is_registered || event.is_expired} className="btn btn-primary w-75">{eventLang.reserve_a_seat}</button>
                <button className="btn btn-outline-secondary w-50">
                    <a href="tel:1234567890">
                        <div>
                            <img className="info-phone mr-3 mb-1" src="phone-icon.svg" />
                            <span>{eventLang.get_help_button}</span>
                        </div>
                    </a>
                </button>
            </div>
        </div>
    )
}

export default EventInfo